@import '../../../styles/vars.scss';

.send-card-step1 {
  background-color: white;
  border-radius: 5px;
  padding: 0px 8px 0px 8px;
  height: 420px;
  width: 420px;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.16);
}
.top {
  padding-top: 7%;
}
.send-card-top-row,
.send-card-mid-row,
.send-card-mid-row2 {
  justify-content: center;
}
.send-card-mid-row2 {
  padding-bottom: 14%;
}
.div-uploader {
  cursor: pointer;
  position: relative;
  user-select: none;
}
.send-card-bott-row {
  border-top: 1px solid #e8e8e8;
  display: flex;
  height: 12%;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 22px;
}
.left-box {
  height: 100%;
  text-align: center;
  top: 35%;
}
.right-box {
  height: 100%;
  text-align: left;
  top: 17%;
}
//svg
.setPos {
  position: absolute;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
}
