@import '../../styles/styles.scss';

.card-success {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &__container {
    width: 305px;
    height: 440px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    box-shadow: 0 3px 10px rgba(75, 75, 75, 0.25);
    border-radius: 5px;
  }

  &__message {
    padding: 0 10px;
    height: 15%;
  }

  &__title {
    font-size: 1.25rem;
    color: $text-color-primary;
    text-align: center;
  }

  &__text,
  &__text-blue {
    padding: 0 15px;
    color: $text-color-primary;
    font-size: 0.875rem;
    font-family: 'Swiss Regular';
    letter-spacing: 0.5px;
    text-align: center;
  }

  &__text-blue {
    color: $blue-color-primary;
    text-decoration: underline !important;
    &.link {
      padding: 0;
    }
  }

  &__input {
    color: $text-color-primary;
    width: 100%;
    height: 20%;
    & .ant-input {
      width: 100%;
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__bottom {
    display: none;
    border-top: 1px solid #b7b7b7;
    justify-content: center;
    margin: 0 auto;
    padding-top: 8px;

    & p {
      font-family: $font-secondary;
      font-weight: 300;
      letter-spacing: 0.2px;
      font-size: 0.875rem;
      text-align: center;
      color: #606060;
      padding: 0 10px;
    }
    & .btn-verify {
      border: 1px solid transparent;
      padding: 0 15px;
    }
  }

  .btn-send-another {
    margin-top: 10px;
    font-size: 0.875rem;
    width: 100%;
    border: none;
    text-align: center;
    text-decoration: underline;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
    &:active {
      transform: scale(0.98);
    }
  }

  .btn-copy-link {
    background-color: $blue-color-primary;
    font-family: $font-secondary;
    font-weight: 300;
    color: #fff;
    font-size: 1rem;
    padding: 7px 25px;
    border: 1px solid $blue-color-primary;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: $blue-color-secondary;
      color: #fff;
    }
    &:active {
      transform: scale(0.98);
    }
  }
}
