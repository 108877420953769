@import '../../styles/vars.scss';

.tooltip {
  position: absolute;
  color: $tooltip-hint-text;
  background-color: $tooltip-hint-background;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 15px 10px;
  font-family: $font-secondary;
  font-weight: 300;
  font-size: 0.75rem;
  letter-spacing: 0.25px;
  max-width: 95%;
  width: max-content;
  transform: scale(0);
  z-index: 2;
  transition: transform 0.2s ease-in-out;

  &[type='hint'] {
    left: 0;
    bottom: -75px;
    transform: scale(0);
    border: $tooltip-hint-border;
    & .tooltip__square {
      border-right: $tooltip-hint-border;
      border-bottom: $tooltip-hint-border;
    }
    &__link {
      display: none;
    }
    &#hint-password {
      bottom: -110px;
      width: 66%;
      & .tooltip__square {
        left: 30%;
      }
    }
    &#hint-downloads {
      bottom: -121px;
      width: 66%;
      & .tooltip__square {
        left: 48%;
      }
    }

    &#hint-copied {
      transform: translate(-50%, -180%) scale(1);
      bottom: 0;
      left: 50%;
      width: 100%;
      & .tooltip__square {
        left: 50%;
        top: calc(100% - 15px);
        border-right: none;
        border-bottom: none;
        border-top: $tooltip-hint-border;
        border-left: $tooltip-hint-border;
      }

      & .tooltip__title {
        display: none;
      }
    }

    &#link-copied {
      transform: scale(1);
      bottom: 46%;
      width: 90%;
      & .tooltip__square {
        left: 50%;
        top: calc(-15px);
        border-left: none;
        border-top: none;
        border-bottom: $tooltip-hint-border;
        border-right: $tooltip-hint-border;
      }

      & .tooltip__title {
        display: none;
      }
    }
  }

  &[type='error'] {
    transform: scale(1) translate(-50%, 110%);
    background-color: $tooltip-warning-background;
    color: $tooltip-warning-text;
    border: $tooltip-warning-border;
    left: 50%;
    bottom: 0;

    & .tooltip__square {
      border-right: $tooltip-warning-border;
      border-bottom: $tooltip-warning-border;
    }

    & .tooltip__title {
      color: #503e30 !important;
    }

    &#hint-password-error {
      transform: scale(1) translate(-50%, 120%);
    }

    &#mail-validation {
      width: 60%;

      & .tooltip__link {
        display: none;
      }

      &-send-again {
        width: 100%;
        transform: scale(1) translate(-50%, 60%);
        & .tooltip__link {
          display: none;
        }
      }
    }
  }

  &[type='message'] {
    position: absolute;
    left: 60%;
    max-width: 320px;
    transform: scale(1);
    padding: 20px;

    & .tooltip__square {
      display: none;
    }

    & .tooltip__title {
      text-align: left;
      &::before {
        font-family: 'Font Awesome\ 5 Free';
        font-weight: 900;
        content: '\f0e0';
        display: inline-block;
        padding-right: 3px;
        color: $tooltip-hint-text;
      }
    }

    & .tooltip__message {
      text-align: left;
    }

    &#hint-for-password {
      max-width: 220px;
      position: absolute;
      top: 50%;
      right: calc(50% - 375px);

      & .tooltip__title {
        text-align: left;
        &::before {
          font-family: 'Font Awesome\ 5 Free';
          font-weight: 900;
          content: '\f05a';
          display: inline-block;
          padding-right: 3px;
          color: $tooltip-hint-text;
        }
      }
    }
  }

  & p {
    margin: 0;
  }

  & .tooltip__square {
    position: absolute;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
    background-color: inherit;
    left: 50%;
    top: -15px;
    width: 16px;
    height: 16px;
    transform: rotate(-135deg) translateY(-50%);
  }

  &__title {
    color: $tooltip-hint-title;
    font-family: 'Swiss Medium';
    text-align: center;
    margin: 0;
  }

  &__message {
    text-align: center;
    word-wrap: break-word;
  }

  &__link {
    border: none;
    background: none;
    width: 100%;
    text-decoration: underline;
    padding-top: 5px;
    cursor: pointer;
    transition: transform 0.2s ease-in;

    &:hover {
      transform: scale(1.05);
    }

    & > svg {
      text-decoration: none;
      margin-left: 5px;
    }
  }
}
