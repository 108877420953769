@import '../../styles/vars.scss';

.download {
  background-color: #fff;
  height: max-content;
  width: 305px;
  border-radius: 5px;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  &__icon {
    padding: 25px 0;
  }

  &__title {
    font-size: 1.25rem;
    color: $text-color-primary;
    text-align: center;
    padding-bottom: 10px;
  }

  &__message {
    padding: 10px 15px 20px 15px;
    color: $text-color-primary;
    font-size: 0.875rem;
    font-family: $font-primary;
    letter-spacing: 0.15px;
    text-align: center;
  }

  &__transfer-data-info {
    width: 100%;
    padding-top: 10px;
    border-top: 1px solid #b7b7b7;
    & ul {
      align-content: baseline;
      display: flex;
      justify-content: center;
      padding-left: 15px;
      width: 100%;
      list-style: none;

      li {
        font-family: $font-secondary;
        letter-spacing: 0.5px;
        font-size: 12px;
        color: $text-color-secondary;
      }
    }
  }

  &__button {
    padding-bottom: 20px;
  }

  .btn-download {
    background-color: $blue-color-primary;
    font-family: $font-secondary;
    color: #fff;
    font-size: 1rem;
    padding: 7px 25px;
    border: 1px solid $blue-color-primary;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: $blue-color-secondary;
      color: #fff;
    }
    &:active {
      transform: scale(0.98);
    }
    &:disabled {
      background-color: $disabled-background;
      color: $disabled-text;
      font-family: $font-secondary;
      cursor: default;
      border: $disabled-border;
      &:hover {
        background-color: $disabled-background;
      }
    }
  }
}

.btn-forgot-password {
  cursor: pointer;
  border: none;
  background: none;
  &:hover {
    color: $blue-color-primary;
    text-decoration: none;
  }
}

.forgotPassword {
  &__background {
    position: fixed;
    inset: 0;
    z-index: 998;
    background-color: rgba(1, 1, 1, 0.65);
    transition: 0.75s ease-out;
    cursor: pointer;
  }

  &__container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 255px;
    height: 436px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 18px 18px 18px;
    box-shadow: 0 3px 10px rgba(75, 75, 75, 1);
    border-radius: 5px;
    z-index: 999;
  }

  &__icon {
    position: relative;
    padding-left: calc(50% - 71px);
  }

  &__title {
    font-family: $font-medium;
    font-size: 1.25rem;
    color: $text-color-primary;
    text-align: center;
    padding: 0 10px;
  }

  &__message {
    line-height: 1.1rem;
    font-size: 0.875rem;
    color: $text-color-primary;
    text-align: center;
  }

  &__button {
    display: flex;
    margin: 0 auto;
    & > button {
      font-size: 1rem;
      width: 100%;
      border: none;
      text-align: center;
      &:hover {
        background-color: $blue-color-secondary;
      }
      &:active {
        transform: scale(0.98);
      }
    }
  }
}
