@font-face {
  font-family: 'Swiss Medium';
  src: url('//db.onlinewebfonts.com/t/d0cf37aa7f9d487d047843a27cf110e3.eot');
  src: url('//db.onlinewebfonts.com/t/d0cf37aa7f9d487d047843a27cf110e3.eot?#iefix') format('embedded-opentype'), url('//db.onlinewebfonts.com/t/d0cf37aa7f9d487d047843a27cf110e3.woff2') format('woff2'), url('//db.onlinewebfonts.com/t/d0cf37aa7f9d487d047843a27cf110e3.woff') format('woff'),
    url('//db.onlinewebfonts.com/t/d0cf37aa7f9d487d047843a27cf110e3.ttf') format('truetype'), url('//db.onlinewebfonts.com/t/d0cf37aa7f9d487d047843a27cf110e3.svg#Swiss721W01-Medium') format('svg');
}
@font-face {
  font-family: 'Swiss Regular';
  src: url('../assets/fonts/SWISS.TTF') format('truetype');
}

@font-face {
  font-family: 'Swiss Bold';
  src: url('../assets/fonts/SWISSB.TTF') format('truetype');
}

@font-face {
  font-family: 'Swiss Condensed';
  src: url('../assets/fonts/SWISSC.TTF') format('truetype');
}

@font-face {
  font-family: 'Swiss Condensed Bold';
  src: url('../assets/fonts/SWISSCB.TTF') format('truetype');
}

$font-primary: 'Swiss Regular', sans-serif;
$font-secondary: 'Swiss Light', sans-serif;
$font-medium: 'Swiss Medium', sans-serif;
$font-bold: 'Swiss Bold', sans-serif;

$text-color-primary: #1e3443;
$text-color-secondary: #6a6a6a;
$text-color-tertiary: #404b52;

$blue-color-primary: #0283bd;
$blue-color-secondary: #0c92cf;

$disabled-background: #aaaaaa;
$disabled-text: #fff;
$disabled-border: 1px solid #aaaaaa;

// TODO
$tooltip-hint-background: #898989;
$tooltip-hint-border: 1px solid
  rgba(
    $color: #707070,
    $alpha: 0.8,
  );
$tooltip-hint-text: #fff !important;
$tooltip-hint-title: #f9f9f9;

$tooltip-warning-background: #fae9a3;
$tooltip-warning-border: 1px solid
  rgba(
    $color: #b99501,
    $alpha: 0.5,
  );
$tooltip-warning-text: #503e30 !important;
