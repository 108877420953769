.loader {
  position: fixed;
  inset: 0;
  background-color: #fff;
  z-index: 10;

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
  }
}
