@import '../styles/vars.scss';

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.175rem;

  &__text {
    display: flex;
  }

  &__icon {
    transform: translateY(10%);
  }

  & h1 {
    font-family: $font-bold;
    padding-left: 20px;
    color: $blue-color-primary;
    margin: 0;
  }
  & h2 {
    font-family: $font-secondary;
    font-weight: 300;
    color: #707e88;
    padding-left: 10px;
    margin: 0;
  }

  &[type='column'] {
    & > .login__icon > svg {
      width: 62px;
      height: 62px;
    }
    flex-direction: column;
    & .logo__planeta {
      font-family: $font-bold;
      padding-left: 0px;
      padding-right: 10px;
      color: $blue-color-primary;
      margin: 0;
    }
    & .logo__send {
      font-family: $font-secondary;
      color: #707e88;
      padding-left: 0px;
      margin: 0;
    }
    & .logo__text {
      display: flex;
    }
  }
}
