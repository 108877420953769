@import '../../styles/styles.scss';

.background {
  transition: 0.75s ease-out;
}

.card {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 305px;
  height: 410px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  box-shadow: 0 3px 10px rgba(75, 75, 75, 0.25);
}
.title-top {
  font-size: 0.625rem;
  color: $text-color-secondary;
  padding: 0 10px;
  display: flex;
  width: 100%;
  align-items: baseline;
  & p {
    padding-left: 10px;
  }
}

.title {
  font-size: 1.25rem;
  color: $text-color-primary;
  padding: 0;
  display: flex;
  padding: 20px 0;
}

.message {
  font-size: 0.875rem;
  color: $text-color-primary;
  text-align: center;
}

.text-block {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.text-blue,
.text-progress {
  color: $text-color-primary;
  font-size: 0.75rem;
  font-family: 'Swiss Regular';
  letter-spacing: 0.5px;
  padding: 2px 0;
}
.text-blue {
  color: $blue-color-primary;
}

.btn-cancel {
  background-color: #fff;
  font-family: $font-secondary;
  font-weight: 300;
  color: $blue-color-primary;
  font-size: 1rem;
  padding: 7px 25px;
  border: 1px solid $blue-color-primary;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: $blue-color-secondary;
    color: #fff;
  }
  &:active {
    transform: scale(0.98);
  }
}

.progress-circle {
  transform: rotate(200deg);
}

.ant-progress-text {
  color: $text-color-primary !important;
  font-family: $font-secondary;
  font-weight: 300;
  font-size: 52px !important;
  transform: translate(-50%, -50%) rotate(-200deg) !important;
  &::after {
    content: '%';
    color: #808080;
    font-size: 30px;
    vertical-align: top;
  }
}

.title-confirm {
  font-size: 1.25rem;
  color: $text-color-primary;
  padding: 0;
  display: flex;
  padding: 20px 0 80px 0;
  text-align: center;
}
.btn-group-confirm {
  border: 1px solid $blue-color-primary;
  border-radius: 5px;
}
.btn-group-confirm button {
  font-family: $font-secondary;
  font-weight: 300;
  background-color: transparent;
  border: 1px solid transparent;
  color: $blue-color-primary;
  padding: 7px 25px;
  cursor: pointer;
  float: left;
}

.btn-group-confirm:after {
  content: '';
  clear: both;
  display: table;
}

.btn-group-confirm button:not(:last-child) {
  border-right: none;
}
.btn-group-confirm button:last-child {
  border: 1px solid $blue-color-primary;
  background-color: $blue-color-primary;
  color: #fff;
}
