@import '../../styles/styles.scss';

.background {
  position: fixed;
  inset: 0;
  z-index: 998;
  background-color: rgba(1, 1, 1, 0.65);
  transition: 0.75s ease-out;
  cursor: pointer;
}

.warning__card {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 255px;
  height: 436px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 18px 18px 18px;
  box-shadow: 0 3px 10px rgba(75, 75, 75, 1);
  border-radius: 5px;
  z-index: 999;
}
.warning__title {
  font-family: 'Swiss Medium';
  font-size: 1.25rem;
  color: $text-color-primary;
  text-align: center;
  padding: 0 10px;
}

.warning__message {
  line-height: 1.1rem;
  font-size: 0.875rem;
  color: $text-color-primary;
  text-align: center;
}

.warning__btn-group {
  display: flex;

  justify-content: center;
}
.warning__btn-register {
  font-size: 1rem;
  width: 100%;
  font-weight: 400 !important;
  border: none;
  text-align: center;
  &:hover {
    background-color: $blue-color-secondary;
  }
  &:active {
    transform: scale(0.98);
  }
}
.warning__btn-later {
  cursor: pointer;
  background: none;
  padding: 5px 15px;
  text-align: center;
  font-size: 0.875rem !important;
  width: 100%;
  border: none !important;
  color: $text-color-secondary !important;
  &:active {
    transform: scale(0.98);
  }
}
