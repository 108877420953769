@import '../../styles/vars.scss';

.login {
  background-color: #fff;
  border-radius: 5px;
  width: 421px;
  height: max-content;

  &__logo {
    padding: 10px 0;
  }

  &__container {
    min-height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__text {
    font-size: 1rem;
    padding: 0 32px;
    text-align: center;
    & p {
      margin: 0;
      &:last-child {
        padding-top: 15px;
      }
    }
  }

  &__btn-access {
    padding: 20px 15px 10px 15px;
    height: fit-content;
  }

  & .btn-submit {
    width: 100%;
  }
}
