@import '../../../styles/vars.scss';

.send-cards {
  flex: 1;
  margin: 0 8px;
  padding: 16px 0;
  height: 100%;
  & .card-title {
    color: $text-color-primary;
    font-size: 1.25rem;
    // font-weight: 600;
  }
  &__container {
    justify-content: center;
    height: 100%;
    align-items: center;
  }
  &__column {
    &_left {
      flex: 0 1 325px;
    }
    &_right {
      flex: 0 1 420px;
    }
    $breakpoint-tablet: 760px;
    @media (max-width: $breakpoint-tablet) {
      flex-grow: 1;
    }
  }
}

.card-left__container {
  background-color: white;
  border-radius: 5px;
  height: 410px;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// -------------------  LEFT CARD  -------------------

.card-left {
  &__top-row {
    align-items: center;
    border-bottom: 1px solid rgba(156, 156, 156, 0.4);
    padding: 9px 25px;
    align-content: center;
    // & svg {
    //   cursor: pointer;
    //   position: absolute;
    //   bottom: 50%;
    //   left: 50%;
    //   transform: translate(-25%, 50%);
    // }
  }

  &__contnent {
    overflow-y: auto;
    position: relative;
    margin-bottom: auto;
    &__list {
      padding: 0;
      list-style: none;

      &-item {
        padding: 5px 25px 0px 25px;

        &__container {
          display: flex;
          border-bottom: 1px solid rgba(156, 156, 156, 0.4);
          padding: 0px 0px 4px 0;
          & .directory {
            &::before {
              font-family: 'Font Awesome\ 5 Free';
              font-weight: 900;
              content: '\f07b';
              display: inline-block;
              padding-right: 5px;
              color: $text-color-secondary;
            }
          }
        }
        &:last-child {
          border-bottom: 0px;
        }
      }
    }
  }

  &__bottom {
    padding: 15px;

    &__data-available {
      color: $text-color-secondary;
      text-align: center;
    }

    &__register-link {
      text-align: center;
    }
  }
}

// -------------------  RIGHT CARD  -------------------

.card-right__container {
  background-color: white;
  border-radius: 5px;
  height: 410px;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.card-right {
  &__form {
    height: 100%;
    display: flex;
    flex-direction: column;
    .ant-tabs {
      flex: 1;
    }
  }

  &__form-group {
    width: 100%;
    padding: 10px 20px 4px 20px;
    & input {
      text-align: left;
    }
  }

  &__bottom {
    border-top: 1px solid rgba(156, 156, 156, 0.4);
    align-items: center;
    padding: 15px 25px 10px 25px;
    border-top: 1px solid rgba(156, 156, 156, 0.4);

    &__right {
      text-align: right;
    }

    &__left > svg {
      padding-left: 20px;
    }
  }
}

// .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//   color: $text-color-primary;
//   text-shadow: none;
// }

// .send-card2-step2-bott-link {
//   border-top: 1px solid rgba(156, 156, 156, 0.4);
//   align-items: center;
//   display: flex;
//   padding: 15px 0 15px 0;

//   & .card2-bott-left {
//     padding-top: 9px;
//   }
// }

.card2-link {
  color: $text-color-primary;
  padding: 10px 40px;
  font-size: 0.875rem;
}

.card2-mail {
  padding: 0;
}

.ant-input-affix-wrapper,
.ant-input {
  padding: 10px;
  font-size: 0.875rem;
  border-radius: 5px;
  border-color: #c3c3c3;
  &::placeholder {
    color: #777777;
  }
}

.ant-form-item {
  margin-bottom: 10px;
}

.ant-input-prefix {
  margin-right: 13px;
  // width: 20%;
  color: $text-color-primary;
}
.text-area {
  height: 129px !important;
  resize: none;
}
// .card2-bott-right {
//   text-align: right;
//   padding-right: 4%;
// }
// .card2-bott-left {
//   padding-left: 4%;
// }

// -------------------  CONFIG CARD  -------------------
.config {
  &__top-row {
    align-items: center;
    border-bottom: 1px solid rgba(156, 156, 156, 0.4);
    padding: 10px 16px 0 10px;
    align-content: center;
    & svg {
      cursor: pointer;
      position: absolute;
      bottom: 50%;
      left: 50%;
      transform: translate(-25%, 50%);
    }
  }
  .config-downloads {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 0 12px;
    border-bottom: 1px solid #d9d9d9;
    &__limits {
      display: flex;
      align-items: baseline;
      .label {
        margin-right: 10px;
        line-height: 32px;
        height: 42px;
      }
    }
  }
  .empty .ant-collapse-content-box {
    padding: 0 !important;
  }
}

.btn-config {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;

  &__close {
    position: absolute;
    right: 35px;
  }

  &:active {
    transform: scale(0.98);
  }
}

.config-content {
  // padding: 0 30px 33px 30px;
  // max-height: 279px;
  // overflow-y: scroll;
  & .input__number {
    background: none;
    border: none;
    padding-left: 15px;
    outline: none;
    color: $text-color-primary !important;
    &::-webkit-outer-spin-button {
      border: 1px solid red;
    }
    &.hidden {
      visibility: hidden;
    }
  }
  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    font-size: 0.875rem;
    color: $text-color-tertiary;
    padding: 12px 0;
    border-bottom: 1px solid rgba(156, 156, 156, 0.2);
    align-items: baseline;
    &:last-child {
      padding-top: 10px;
      padding-left: 12px;
      border: none;
    }
  }
}

// .config-content__password {
//   display: flex;
//   align-items: baseline;
// }

.info-icon {
  display: flex;
  padding-left: 5px;
  cursor: pointer;
  &:hover + .tooltip#hint-password {
    transform: scale(1);
  }
}

.info-icon__downloads {
  display: flex;
  padding-left: 5px;
  cursor: pointer;
  &:hover + .tooltip#hint-downloads {
    transform: scale(1);
  }
}

.hint,
.hint__downloads {
  display: none;
  position: fixed;
  color: #fff !important;
  background-color: #898989;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 15px 10px;
  font-family: $font-secondary;
  font-weight: 300;
  font-size: 0.875rem;
  max-width: 320px;
  top: 20%;
}

.ant-input-password {
  & input {
    text-align: left;
    color: #404b52;
  }
}

.ant-input-number-input {
  height: 50%;
  color: $text-color-primary;
}

.ant-input-number {
  &:hover + :first-child {
    border: 10px solid red;
  }
}

.input__text-left {
  text-align: left;
  color: #404b52;
}

.ant-select-arrow {
  color: $blue-color-primary;
}

.ant-select-arrow {
  top: 50%;
  height: 16px;
  width: 16px;
}

.ant-select-item-option-content {
  color: $text-color-primary !important;
  border: 1px solid transparent;
  padding: 0;
  &:hover {
    border: 1px solid $blue-color-secondary;
    background-color: transparent;
    color: #fff;
  }
}
.ant-select-item-option {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}

.ant-select-item-option > :first-child {
  color: $blue-color-primary;
  padding: 10px 10px;

  &:hover {
    color: #fff;
  }
}

.ant-select-selection-item {
  color: $text-color-primary !important;
  text-align: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: $text-color-primary !important;
  border: none;
  padding: 0;
}
