@import '../../styles/vars.scss';

.downloadSuccess {
  background-color: #fff;
  height: max-content;
  width: 305px;
  border-radius: 5px;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  &__icon {
    padding: 25px 0;
  }

  &__title {
    font-size: 1.25rem;
    color: $text-color-primary;
    text-align: center;
    padding-bottom: 10px;
  }

  &__message {
    padding: 10px 25px 20px 25px;
    color: $text-color-primary;
    font-size: 0.875rem;
    font-family: 'Swiss Regular';
    letter-spacing: 0.15px;
    text-align: center;
  }

  &__transfer-data-info {
    width: 100%;
    padding-top: 10px;
    border-top: 1px solid #b7b7b7;
    & ul {
      align-content: baseline;
      display: flex;
      justify-content: center;
      padding-left: 15px;
      width: 100%;
      list-style: none;

      li {
        font-family: $font-secondary;
        font-weight: 300;
        letter-spacing: 0.5px;
        font-size: 12px;
        color: $text-color-secondary;
      }
    }
  }

  &__button {
    padding-bottom: 20px;
    & > button {
      border: 1px solid transparent;
      padding: 0 10px;
    }
  }

  .btn-download {
    background-color: $blue-color-primary;
    font-family: $font-secondary;
    font-weight: 300;
    color: #fff;
    font-size: 1rem;
    padding: 7px 25px;
    border: 1px solid $blue-color-primary;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: $blue-color-secondary;
      color: #fff;
    }
    &:active {
      transform: scale(0.98);
    }
    &:disabled {
      background-color: $disabled-background;
      color: $disabled-text;
      font-family: $font-secondary;
      font-weight: 300;
      cursor: default;
      border: $disabled-border;
      &:hover {
        background-color: $disabled-background;
      }
    }
  }

  &__footer {
    color: $text-color-secondary;
    font-size: 0.75rem;
    text-align: center;
    padding: 0 10px 20px 10px;
  }
}
