@import '../../styles/vars.scss';

.transfer-data {
  background-color: white;
  border-radius: 5px;
  height: 440px;
  width: 510px;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.16);
  margin-left: 15px;
  word-wrap: break-word;

  &__top {
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 10px 0px 10px;

    &__title {
      font-family: 'Swiss Medium';
      font-size: 20px;
      color: $text-color-primary;
      padding-left: 15px;
    }

    &__info {
      & ul {
        align-content: baseline;
        display: flex;
        justify-content: flex-start;
        padding-left: 15px;
        width: 100%;
        list-style: none;

        li {
          font-family: $font-secondary;
          font-weight: 300;
          letter-spacing: 0.5px;
          font-size: 12px;
          color: $text-color-secondary;
        }
      }
    }
  }
  &__content {
    &__wrapper {
      flex-direction: row;
      height: 66%;
      overflow: auto;
      scroll-behavior: auto;
      border-bottom: 1px solid #e8e8e8;
      padding: 15px 25px;

      .left__link-title {
        font-family: 'Swiss Medium';
        margin: 0;
      }
      & p {
        padding-right: 20px;
      }
      .left__link-link {
        font-size: 0.75rem;
        background: none;
        border: none;
        color: $blue-color-primary;
        text-decoration: underline;
        margin-bottom: 20px;
        cursor: pointer;

        // &::after {
        //   font-family: 'Font Awesome\ 5 Free';
        //   font-weight: 900;
        //   content: '\f0c5';
        //   display: inline-block;
        //   padding-left: 10px;
        //   color: $text-color-primary;
        // }

        &:hover {
          text-decoration: none;
          color: $blue-color-secondary;
        }
      }
    }
    &__left {
      &__message {
        p {
          white-space: pre-wrap;
        }
      }
    }
  }

  &__bottom {
    padding: 0 5px;
    & .bottom__icon {
      padding: 25px 20px 20px 20px;
      text-align: center;
    }

    & .bottom__text {
      font-size: 0.75rem;
      color: $text-color-secondary;
      margin: auto 0;
    }
  }
}

#input-link {
  color: red;
}

.ant-space-item-split .transfer-data__divider {
  height: 3px;
  width: 3px !important;
  border-radius: 50%;
  background-color: #777575;
}

.content__ul {
  list-style: none;
  & li {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  & :first-child {
    padding-top: 0;
  }
  & :last-child {
    border: none;
  }
  &__list {
    display: flex;
    color: red;
    flex-direction: column;
    padding-top: 1%;

    & .data-info {
      color: #6a6a6a;
      font-size: 0.625rem;
    }
  }
}
