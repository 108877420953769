@import 'vars.scss';
/*---------------------------------------------------------All----------------------------------------------------*/
body {
  font-family: $font-primary;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
.back-image {
  background-image: url('../assets/mountain.png');
  background-position: center center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-size: 100% 100%; /*size*/
}

.layout {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/*---------------------------------------------------------Nav----------------------------------------------------*/

.header {
  align-items: center;
  background-color: white;
  justify-content: center;
  width: 100%;
  padding: 15px 30px;
}
.logo {
  text-align: start;
}
.nav {
  text-align: end;
}

.username {
  padding: 0 25px;
  &::before {
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    content: '\f007';
    display: inline-block;
    padding-right: 10px;
    color: $text-color-secondary;
  }
}

/*---------------------------------------------------------Main----------------------------------------------------*/
.content {
  align-items: center; /*Horizontal*/
  display: flex;
  height: 84vh; /*size*/
  justify-content: center; /*Vertical*/
  width: 100%;
  z-index: 2;
}

/*---------------------------------------------------------Footer----------------------------------------------------*/
.foot {
  align-items: center; /*Horizontal*/
  background-color: transparent; /*To see back image*/
  height: 8vh; /*size*/
  justify-content: center; /*Vertical*/
  width: 100%;
  z-index: 1;
}
.footer-right {
  padding-right: 70px;
  text-align: end;
}
.footer-left {
  padding-left: 40px;
  text-align: start;
}
/*-----------------------------------------------------------Drop Zone-------------------------------------------------*/

.dropZone {
  position: fixed;
  inset: 0;
  align-items: center;
  background: $blue-color-primary;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  z-index: 5;

  p {
    font-family: $font-primary;
    font-size: 26px;
  }
}

/*---------------------------------------------------------Cards----------------------------------------------------*/
// /*----------------------------------------Card1-------------------------------------*/

/*----------------------------------------CardsStep2-------------------------------------*/
.send-card-step2 {
  display: flex;
}
.send-card1-step2 {
  background-color: white;
  border-radius: 5px;
  height: 410px;
  width: 305px;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.16);
}

.send-card-step2-top-right {
  padding-right: 18px;
}

/*Menu*/
.dropdown-menu {
  // padding: 10px;
  // border: 1px solid #777777;
  // border-radius: 5px;
  padding: 10px 0;

  &__item {
    font-family: $font-secondary;
    color: $text-color-primary;
    font-size: 1.125rem;
    padding: 10px 20px;
    border: 1px solid transparent;
    &:hover {
      // color: #fff;
      // background-color: $blue-color-secondary;
      // border: 1px solid $blue-color-secondary;
      // font-family: $font-primary;
    }
  }
}

.list-item {
  border-bottom: 1px solid #ebebeb;
  list-style: none;
  width: 95%;
  &:last-child {
    border-bottom: 0px;
  }
}

.send-card-step2-mid-sub-row {
  padding-top: 2%;
  padding-bottom: 2%;
  height: 66%;
}
.send-card-step2-mid-row {
  height: 75%;
}
.send-card-step2-mid-row-file {
  display: flex;
  flex-direction: column;
  padding-top: 1%;
  padding-left: 25px;
}

.send-card-step2-mid-row-delete {
  cursor: pointer;
  text-align: left;
}

.send-card-step2-bott-row {
  padding-top: 15px;
}

.send-card-step2-bott-row,
.send-card-step2-bott2-row {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 10%;
}

// .config-content__row {
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
//   font-size: 0.875rem;
//   color: $text-color-tertiary;
//   padding: 12px 0;
//   border-bottom: 1px solid rgba(156, 156, 156, 0.2);
//   align-items: baseline;
//   &:last-child {
//     padding-top: 20px;
//     border: none;
//   }
// }

.input__text-left {
  text-align: left;
  color: #404b52;
}
// .config-content__row > div + div,
// .config-content__row > div > div,
// .ant-select-arrow {
//   color: $blue-color-primary;
// }

.ant-select-arrow {
  top: 50%;
  height: 16px;
  width: 16px;
}

.ant-select-item-option-content {
  color: $text-color-primary !important;
  border: 1px solid transparent;
  padding: 0;
  &:hover {
    border: 1px solid $blue-color-secondary;
    background-color: transparent;
    color: #fff;
  }
}
.ant-select-item-option {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}

.ant-select-item-option > :first-child {
  color: $blue-color-primary;
  padding: 10px 10px;

  &:hover {
    color: #fff;
  }
}

.ant-select-selection-item {
  color: $text-color-primary !important;
  text-align: center;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: $text-color-primary !important;
  border: none;
  padding: 0;
}

/*----------------------------Card2----------------------*/

// .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//   color: $text-color-primary;
//   text-shadow: none;
// }

// .ant-tabs-ink-bar {
//   position: absolute;
//   background: black;
//   pointer-events: none;
// }
.ant-tabs-tab {
  color: $text-color-secondary;
  font-size: 1.25rem;
  padding: 9px 30px 12px 30px;
}

.ant-drawer-body {
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 12px;
  padding-right: 12px;
}

.ant-popover-title,
.ant-popover-inner-content {
  max-width: 190px;
}

.ant-popover-inner-content {
  font-size: 0.8rem;
}

.ant-collapse-borderless {
  background-color: transparent;
}

.card2-link {
  color: $text-color-primary;
  padding: 10px 40px;
  font-size: 0.875rem;
}

.card2-mail {
  padding: 0;
}

.ant-input-affix-wrapper,
.ant-input {
  padding: 10px;
  font-size: 0.875rem;
  border-radius: 5px;
  border-color: #c3c3c3;
  &::placeholder {
    color: #777777;
  }
}

.ant-form-item {
  margin-bottom: 10px;
}

.ant-input-prefix {
  // margin-right: 4px;
  // width: 20%;
  color: $text-color-primary;
}
.text-area {
  height: 129px !important;
  resize: none;
}

/*---------------------------------------------------------Fonts----------------------------------------------------*/

.data-available {
  letter-spacing: 0.2px;
  color: #727272;
  font-size: 0.75rem;
}
.drag-title {
  font-family: $font-medium;
  color: white;
  font-size: 60px;
}
.send-title {
  font-size: 1.5rem;
  color: $text-color-primary;
  line-height: 29px;
}
.nav-font {
  font-size: 1.125rem;
  font-family: $font-secondary;
  color: $text-color-primary !important;
}

.foot-font {
  font-family: $font-secondary;
  font-size: 0.9rem;
  letter-spacing: 0.2px;
  color: $text-color-tertiary !important;
}
.text-link {
  color: $blue-color-primary !important;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.card-title {
  color: $text-color-primary;
  font-size: 1.25rem;
  // font-weight: 600;
}
.list-item-font {
  font-size: 0.875rem;
  color: $text-color-primary;
}
.data-info {
  color: #6e6e6e;
  font-size: 0.75rem;
  & u {
    color: $text-color-tertiary;
  }
}
/*---------------------------------------------------------Buttons----------------------------------------------------*/
button {
  &:active {
    // transform: scale(0.95);
  }
}

.ant-switch-checked {
  background-color: $blue-color-primary;
}
.btn-cancel {
  background-color: #fff;
  font-family: $font-secondary;
  font-weight: 300;
  color: $blue-color-primary;
  font-size: 1rem;
  padding: 7px 25px;
  border: 1px solid $blue-color-primary;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: $blue-color-secondary;
    color: #fff;
  }
  &:active {
    transform: scale(0.98);
  }
}
.btn-primary-custom {
  border-radius: 8px;
  font-family: $font-secondary;
  font-weight: 300;
  font-size: 1rem;
  background-color: $blue-color-primary;
  min-width: 120px;
  height: 40px;
  letter-spacing: 0.8px;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: $blue-color-secondary;
    color: #fff;
  }
  &.btn-cancel {
    background-color: #fff;
    font-family: $font-secondary;
    font-weight: 300;
    color: $blue-color-primary;
    font-size: 1rem;
    padding: 7px 25px;
    border: 1px solid $blue-color-primary;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: $blue-color-secondary;
      color: #fff;
    }
    &:active {
      transform: scale(0.98);
    }
  }
}
.btn {
  color: $text-color-primary;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  letter-spacing: 0.2px;
  font-size: 1rem;
  line-height: 2.5rem;

  &:hover {
    color: $blue-color-secondary;
    text-decoration: none;
  }
}

/*---------------------------------------------------------Links----------------------------------------------------*/
.link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/*----------------------------------------------------------Animations-------------------------------*/

@keyframes ldio-h3uuglhk2zk {
  0% {
    height: 100px;
    left: calc(50% - 46px);
    opacity: 1;
    top: calc(50% - 48px);
    width: 100px;
  }

  100% {
    height: 160px;
    left: 20px;
    opacity: 0;
    top: 20px;
    width: 160px;
  }
}
.ldio-h3uuglhk2zk div {
  animation: ldio-h3uuglhk2zk 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  border-radius: 50%;
  border-style: solid;
  border-width: 1.5px;
  opacity: 1;
  position: absolute;
  border-color: #cdd1e1cf;
}
// .ldio-h3uuglhk2zk div:nth-child(1) {
//     animation-delay: -0.5s;
//     border-color: red;
// }
.ldio-h3uuglhk2zk div:nth-child(2) {
  animation-delay: -0.7s;
  border-color: #cdd1e1cf;
}
.loadingio-spinner-ripple-1n1klxn2k0t {
  background: white;
  height: 200px;
  overflow: hidden;
  width: 200px;
}
.ldio-h3uuglhk2zk {
  backface-visibility: hidden;
  height: 100%;
  position: relative;
  transform-origin: 0 0; /* see note above */
  transform: translateZ(0) scale(1);
  width: 100%;
}
.ldio-h3uuglhk2zk div {
  box-sizing: content-box;
}

/*-------------------------------------Scrollbar--------------------------------------------*/
::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  min-height: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*-----------------------------------------Icons----------------------------------------------*/
.icon-unselect {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
  button {
    line-height: 2rem;
  }
}
